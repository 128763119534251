import React from "react"
import Layout from "../components/Layout"
import RaftDigitalLogo from "../images/RaftDigital-Logo.svg";

export default () => {
  return (
    <Layout>
      <div className="text-center w-full p-20 text-white relative z-10">
        <h1 className="lg:text-5xl font-black text-white text-3xl leading-none">Ecommerce - Websites - Applications</h1>
        <p className="mt-5 text-xl"><a className="underline" href="mailto:harrison@raftdigital.co.uk">harrison@raftdigital.co.uk</a></p>
      </div>
    </Layout>
  )
}
